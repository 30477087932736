/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { faAngleLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getDoc } from '../../shared/firebaseManager';
import { InsightDoc } from '../../shared/interface';

import classes from './InsightDetail.module.css';

const InsightDetail = (props: any) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [insight, setInsight] = useState<InsightDoc>();

  useEffect(() => {
    getDoc<InsightDoc>(`insight/${id}`).then((doc) => {
      if (!doc) {
        navigate('/404');
        return;
      }
      setInsight(doc);
    });
  }, [id, navigate]);

  const download = (event: any) => {
    event.preventDefault();

    if (!insight?.file) {
      return;
    }
    saveAs(insight.file.filePath, insight.file.fileName);
  };

  function createMarkup() {
    return { __html: insight?.content ?? '' };
  }

  return (
    <div className={`${classes.container} maxWidth`}>
      <h2 className={classes.title}>
        <FontAwesomeIcon
          icon={faAngleLeft}
          color="#ea0029"
          onClick={() => navigate(-1)}
        />{' '}
        {insight?.title}
      </h2>
      <h3>{insight?.subtitle}</h3>
      <p className={classes.date}>{insight?.date}</p>
      {insight?.file ? (
        <p className={classes.file}>
          <a download href={insight?.file?.filePath} onClick={download}>
            <FontAwesomeIcon icon={faDownload} /> {insight?.file?.fileName}
          </a>
        </p>
      ) : null}
      <div
        className={classes.contentContainer}
        dangerouslySetInnerHTML={createMarkup()}
      ></div>
    </div>
  );
};

export default InsightDetail;
