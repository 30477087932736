/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import classes from './Banner.module.css';

import mainBanner1 from 'assets/img/main_banner1.jpg';
import mainBanner2 from 'assets/img/main_banner2.jpg';
import mainBanner3 from 'assets/img/main_banner3.jpg';
import mainBanner4 from 'assets/img/main_banner4.jpg';
import mainBanner5 from 'assets/img/main_banner5.jpg';
import mainBanner6 from 'assets/img/main_banner6.jpg';
import mainBanner7 from 'assets/img/main_banner7.jpg';
import mainBanner8 from 'assets/img/main_banner8.jpg';

const Banner = () => {
  const banners = [
    mainBanner1,
    mainBanner2,
    mainBanner3,
    mainBanner4,
    mainBanner5,
    mainBanner6,
    mainBanner7,
    mainBanner8,
  ];

  // autoplay를 사용하려면 넣어야 함
  SwiperCore.use([Autoplay]);

  return (
    <Swiper
      modules={[Pagination, Navigation]}
      pagination={{ clickable: true }}
      loop={true}
      autoplay={{ delay: 3000 }}
      effect="fade"
      navigation
      color="#FFFFFF"
      className={classes.bannerContainer}
    >
      {banners.map((banner, i) => (
        <SwiperSlide key={i}>
          <div
            className={classes.bannerImg}
            style={{ backgroundImage: 'url(' + banner + ')' }}
          ></div>
        </SwiperSlide>
      ))}
      <div className={classes.titleContainer}>
        <h1 className={classes.title}>
          믿을 수 있는 배달 창업의 시작,
          <br />
          고스트키친
        </h1>
      </div>
    </Swiper>
  );
};

export default Banner;
