/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Route, Routes } from 'react-router-dom';

import Ydp from './Ydp/Ydp';
import Nowon from './Nowon/Nowon';
import Gwanak from './Gwanak/Gwanak';
import Gwanak2 from './Gwanak2/Gwanak2';
import Songpa from './Songpa/Songpa';
import Kangnam from './Kangnam/Kangnam';
import Samsung from './Samsung/Samsung';

const SiteInfo = () => {
  return (
    <Routes>
      <Route path="ydp" element={<Ydp/>} />
      <Route path="gwanak" element={<Gwanak/>} />
      <Route path="gwanak2" element={<Gwanak2/>} />
      <Route path="nowon" element={<Nowon/>} />
      <Route path="songpa" element={<Songpa/>} />
      <Route path="kangnam" element={<Kangnam/>} />
      <Route path="samsung" element={<Samsung/>} />
    </Routes>
  );
};

export default SiteInfo;
