/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import classes from './Card.module.css';

const Card = (props: any) => {
  const style = {
    backgroundColor: props.color ?? '#ffffff',
  }
  return <div style={style} className={classes.card}>{props.children}</div>;
};

export default Card;
