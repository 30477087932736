export const environment = (process.env.REACT_APP_DEPLOY_ENV === 'production') ? {
  production: true,
  version: '20211118-150456',
  firebase: {
    apiKey: 'AIzaSyDOwd8SczxpCH5U9R-5ikRNHS-iGgPWkgU',
    authDomain: 'toe-prod.firebaseapp.com',
    databaseURL: 'https://toe-prod.firebaseio.com',
    projectId: 'toe-prod',
    storageBucket: 'toe-prod.appspot.com',
    messagingSenderId: '1046149416932',
    appId: '1:1046149416932:web:44ae341281a8fa41'
  }
} : {
  production: false,
  version: '20211118-150456',
  firebaseProd: {
    apiKey: 'AIzaSyDOwd8SczxpCH5U9R-5ikRNHS-iGgPWkgU',
    authDomain: 'toe-prod.firebaseapp.com',
    databaseURL: 'https://toe-prod.firebaseio.com',
    projectId: 'toe-prod',
    storageBucket: 'toe-prod.appspot.com',
    messagingSenderId: '1046149416932',
    appId: '1:1046149416932:web:44ae341281a8fa41'
  },
  firebase: {
    apiKey: 'AIzaSyCGwJjdpxjXYKetDqcxhC-5phDkuJAsNG4',
    authDomain: 'toe-dev-2019.firebaseapp.com',
    databaseURL: 'https://toe-dev-2019.firebaseio.com',
    projectId: 'toe-dev-2019',
    storageBucket: 'toe-dev-2019.appspot.com',
    messagingSenderId: '825253517610',
    appId: '1:825253517610:web:8ac0c337694cf3fc'
  }
}
