/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { useNavigate } from "react-router-dom";

import { faCheck, faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "../../components/UI/Button";
import Banner from "../../components/Banner/Banner";
import Card from "../../components/UI/Card";

import ToePos from 'assets/img/toe_pos.png';
import ToePos2 from 'assets/img/toe_pos2.png';
import LogoBaemin from 'assets/img/logo_baemin.png';
import Ghostkitchen1 from 'assets/img/ghostkitchen1.png';
import Ghostkitchen2 from 'assets/img/ghostkitchen2.jpg';
import Ghostkitchen3 from 'assets/img/ghostkitchen3.jpg';
import Ghostkitchen4 from 'assets/img/ghostkitchen4.png';
import Ghostkitchen5 from 'assets/img/ghostkitchen5.png';
import Ghostkitchen6 from 'assets/img/ghostkitchen6.png';
import Kitchen from 'assets/img/kitchen.png';
import { ReactComponent as SeoulMap } from 'assets/img/seoul_map.svg';

import classes from './Home.module.css';

const Home = (props: any) => {
  const navigate = useNavigate();

  return (
    <div className={classes.home}>
      <Banner></Banner>
      <div className='maxWidth'>
        <h2 className={classes.textLarge}>
          <strong>고스트키친은</strong><br />
          배달의민족 출신이 모여<br />
          월매출 1억 배달음식점 운영 경험을<br />
          바탕으로 시작한 공유주방 플랫폼입니다.
        </h2>
        <div className={classes.videoContainer}>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/BpBmImFqMxg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
      <div className={classes.backgroundPink}>
        <h2 className={`${classes.textLarge} maxWidth`}>
          배달 수요가 가장 많은 A급 상권에만!<br />
          <strong>서울・경기 총 8개 지점</strong>
        </h2>
        {/* <img src={} alt="고스트키친 지점 지도" /> */}
        <div className={classes.svgContainer}>
          <SeoulMap height="100%" width="100%" />
        </div>
        <div className={classes.buttonContainer}>
          <Button onClick={() => navigate('/site')}>8개 지점 상권 자세히 보기</Button>
        </div>
      </div>
      <div>
        <h2 className={`${classes.textLarge} maxWidth`}>
          배달창업의 시작부터 확장까지,<br />
          <strong>성장을 위한 모든 솔루션 4가지</strong>
        </h2>
        <div className={classes.quotation}>
          <FontAwesomeIcon icon={faQuoteLeft}></FontAwesomeIcon>
          과거 운영 경험을 바탕으로<br />
          사장님과의 안정적인 정착과 확장을 위해<br />
          최선의 솔루션을 제공합니다.
          <FontAwesomeIcon icon={faQuoteRight}></FontAwesomeIcon>
        </div>
        <div className={classes.logoContainer}>
          {
            [
              { name: '밥투정', color: '#432a19' },
              { name: '마티스그린', color: '#004143' },
              { name: '더스테이크조인트', color: '#24292b' },
              { name: '난나나', color: '#ec6c74' },
              { name: '도쿄밥상', color: '#17344e' },
              { name: '도쿄카레', color: '#ffa409' },
            ].map((brand, index)=> {
              return (
                <div key={index} style={{backgroundColor: brand.color}}>
                  <img src={require(`assets/img/brand_logo${index+1}.png`)} alt={brand.name} />
                </div>
              )
            })
          }
        </div>
        <p className={classes.caption}>고스트키친이 운영했던 배달전문 브랜드</p>
      </div>

      <section className={`${classes.contentBox} maxWidth`}>
        <div className={classes.contentNo}>01</div>
        <div className={classes.contentHeader}>
          월매출 1억을 가능케 한 시스템, 발가락 POS
        </div>
        <div className={classes.contentText}>
          매출 1억의 노하우와 80만개의 주문데이터를 기반으로 
          직접 만들어 사용했던 시스템을 업그레이드 했습니다.<br /><br />
          쉽고 빠르게 여러 플랫폼의 주문을 접수하여 짧은 시간동안 
          더 많은 매출을 낼 수 있었습니다.
        </div>
        <div className={classes.contentImg}>
          <img src={ToePos} alt="발가락POS" />
        </div>
        <div className={classes.contentCheckList}>
          <FontAwesomeIcon icon={faCheck} color="#27c200" /> 배민, 쿠팡이츠, 요기요, 샵인샵 통합 주문 관리<br />
          <FontAwesomeIcon icon={faCheck} color="#27c200" /> 주문 통계 리포트 상시 제공<br />
          <FontAwesomeIcon icon={faCheck} color="#27c200" /> 울트라콜 깃발 위치 한 눈에 확인<br />
          <FontAwesomeIcon icon={faCheck} color="#27c200" /> 배달대행사 프로그램을 연동한 자동배차 시스템<br />
        </div>
      </section>

      <section className={`${classes.contentBox} maxWidth`}>
        <div className={classes.contentNo}>02</div>
        <div className={classes.contentHeader}>
          체계적인 입점 시스템과 맞춤 온보딩 교육
        </div>
        <div className={classes.contentText}>
          배달창업이 처음이신 사장님부터 베테랑 사장님까지 
          맞춤으로 담당 매니저가 온보딩을 진행해 드립니다.<br />
          최소 7일 내 빠르게 가게 오픈을 지원해 드립니다. 
        </div>
        <div className={classes.contentImg}>
          <div className={classes.contentImgCaption}>
            <img src={LogoBaemin} alt="배민" />
            <div className={classes.contentCheckList}>
              <FontAwesomeIcon icon={faCheck} color="#27c200" /> 배달플랫폼 등록대행<br />
              <FontAwesomeIcon icon={faCheck} color="#27c200" /> 수수료 협약<br />
            </div>
          </div>
          <div className={classes.contentImgCaption}>
            <img src={ToePos2} alt="발가락POS" />
            <div className={classes.contentCheckList}>
              <FontAwesomeIcon icon={faCheck} color="#27c200" /> 카드사가맹 등록대행<br />
              <FontAwesomeIcon icon={faCheck} color="#27c200" /> 배달대행사 연계, POS연동<br />
            </div>
          </div>
          <div className={classes.contentImgCaption}>
            <img src={Ghostkitchen1} alt="교육" />
            <div className={classes.contentCheckList}>
              <FontAwesomeIcon icon={faCheck} color="#27c200" /> 배달전문점 운영교육<br />
              <FontAwesomeIcon icon={faCheck} color="#27c200" /> 운영 노하우 콘텐츠 발행<br />
            </div>
          </div>
        </div>
      </section>

      <section className={`${classes.contentBox} maxWidth`}>
        <div className={classes.contentNo}>03</div>
        <div className={classes.contentHeader}>
          배달음식점 운영에 최적화한 주방 동선과 시설
        </div>
        <div className={classes.contentText}>
          U자 형태의 최소의 통행량으로 기물을 배치해 
          최고의 효율을 낼 수 있는 동선으로 설계했습니다.<br /><br />
          최소 6개월 동안 매일같이 머무르는 공간인 만큼  
          시설, 청결, 사생활을 모두 고려하였습니다. 
        </div>
        <div className={classes.contentImg}>
          <div className={classes.contentImgCaption}>
            <img src={Ghostkitchen2} alt="주방시설1" />
          </div>
          <div className={classes.contentImgCaption}>
            <img src={Ghostkitchen3} alt="주방시설2" />
          </div>
        </div>
        <div className={classes.contentCheckList}>
          <FontAwesomeIcon icon={faCheck} color="#27c200" /> 스테인레스 스틸로 마감하여 위생관리 용이<br />
          <FontAwesomeIcon icon={faCheck} color="#27c200" /> 천장부터 바닥까지 분리한 완전 독립 주방<br />
          <FontAwesomeIcon icon={faCheck} color="#27c200" /> 전문 청소업체를 통한 시설관리<br />
        </div>
        <div className={classes.kitchenFloorPlan}>
          <img src={Kitchen} alt="주방도면" />
          <div className={classes.movementOrder}>
            {
              ['주문접수', '재료준비', '주문서 확인/포장', '조리', '픽업대'].map((orderTitle, index) => {
                return (
                  <div key={index}><p>{orderTitle}</p></div>
                )
              })
            }
          </div>
        </div>
      </section>

      <section className={`${classes.contentBox} maxWidth`}>
        <div className={classes.contentNo}>04</div>
        <div className={classes.contentHeader}>
          고키 핫라인 고객센터와 콘텐츠 제공
        </div>
        <div className={classes.contentText}>
          가게에서 발생하는 문제와 요청을 다이렉트로 해결해 드리는 
          고객센터와 카카오톡 채널을 운영합니다.<br /><br />
          장사에 바쁘신 사장님을 위해 가게 운영에 도움이 될 수 있는 
          배달트렌드・인사이트 최신 정보를 무료로 제공해 드립니다.
        </div>
        <div className={classes.contentImg}>
          <div className={classes.contentImgCaption}>
            <img src={Ghostkitchen4} alt="고객센터" />
          </div>
          <div className={classes.contentImgCaption}>
            <img src={Ghostkitchen5} alt="콘텐츠1" />
          </div>
          <div className={classes.contentImgCaption}>
            <img src={Ghostkitchen6} alt="콘텐츠2" />
          </div>
        </div>
      </section>

      <div className={classes.buttonContainer}>
        <Button onClick={() => navigate('/contact')}>상담 신청 하기</Button>
      </div>

      <div className="maxWidth">
        <h2 className={classes.textLarge}>
          고스트키친을 선택한<br />
          <strong>사장님들의 생생 후기</strong>
        </h2>
        <p>
          젊은 초보 사장님부터<br />
          월 매출 7,000만원을 달성한 사장님까지!<br />
          수 많은 사장님들이 고스트키친을 선택한 이유
        </p>
        <div className={classes.videoContainer}>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/pR2W-2RCtKs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        <div className={classes.videoContainer}>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/hfTexDECB1c" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        <div className={classes.cardList}>
          <Card>
            배달업 자체를 잘 몰랐는데<br />
            고스트키친 매니저님들 덕분에<br />
            <span className={classes.textHighlight}>체계적으로 시작할 수 있었어요.</span>
          </Card>
          <Card color="#fff9f8">
            다른 공유주방에 입점했다가<br />
            시설 관리 문제로 고스트키친으로 옮겼어요.<br />
            <span className={classes.textHighlight}>기본 시설이 좋고 관리대응을 정말 잘 해줘요.</span>
          </Card>
          <Card>
          <span className={classes.textHighlight}>매출수수료가 없어서</span><br />
            월 고정비만 내면 된다는 점이 제일 매력적이에요.
          </Card>
          <Card color="#fff9f8">
          <span className={classes.textHighlight}>발가락POS가 정말 편해요.</span><br />
            다른 공유주방에서도 쓸 수 있으면 좋겠어요.
          </Card>
          <Card>
            원래 포장용기를 직접 구매하려고 했는데<br />
            고스트키친을 통해서 <span className={classes.textHighlight}>저렴하게 납품받고 있어요.</span>
          </Card>
        </div>
      </div>
      <section>
        <div className="maxWidth" style={{padding: '1em'}}>
          <strong className={classes.textLarge}>
            100여개의 브랜드가<br />
            고스트키친과 함께 합니다.
          </strong>
        </div>
        <div className={classes.animateImage}>
          <div className={classes.brand_logo}></div>
        </div>
      </section>
    </div>
  )
}

export default Home;
