/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
const NotFound = () => {
  return (
    <div className="maxWidth" style={{ marginTop: '5em', padding: '5em 0' }}>
      요청하신 페이지를 찾을 수 없습니다.
    </div>
  );
};

export default NotFound;
