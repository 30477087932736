/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import classes from './Contact.module.css';
import ContactForm from './ContactForm';

const Contact = () => {
  return (
    <div className={classes.contact}>
      <div className={classes.headerContainer}>
        <h1>
          고스트키친을 직접 만나보세요
          <br />
          입점상담 ・ 계약문의
        </h1>
      </div>
      <div className="maxWidth">
        <div className={classes.contentContainer}>
          <h2>입점 진행 절차</h2>
          <div className={classes.contactOrder}>
            {[
              '상담신청',
              '지점투어',
              '계약서 작성 <br /> 보증금 납부',
              '오픈준비 (온보딩)',
              '영업시작',
            ].map((item, index) => {
              return (
                <div key={index} className={classes.contactItem}>
                  <div className={classes.icon}>
                    <img
                      src={require(`assets/img/contact${index + 1}.png`)}
                      alt={item}
                    />
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: item }}></p>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classes.contentContainer}>
          <h2>입점 상담 예약</h2>
          <p>
            ・ 신청 후 24시간 이내로 담당자가 연락드립니다.(휴무일 제외) <br />
            ・ 부동산 문의는 <a href="mailto:contact@buttonsoup.co">contact@buttonsoup.co</a> 로 메일보내주세요.
          </p>
        </div>
      </div>
      <div className={classes.formContainer}>
        <ContactForm />
      </div>
    </div>
  );
};

export default Contact;
