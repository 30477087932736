/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import InsightItem from '../../components/InsightItem/InsightItem';
import { getDocsArrayWithWhere } from '../../shared/firebaseManager';
import { InsightDoc } from '../../shared/interface';

import classes from './InsightAll.module.css';

const InsightAll = (props: any) => {
  const { category } = useParams<Record<string, string | undefined>>();
  const [insights, setInsights] = useState<InsightDoc[]>([]);
  const navigate = useNavigate();

  const categoryMap = {
    ceostory: '고키 사장님 스토리',
    info: '외식업 정보 공유',
    analytics: '고키 통계분석',
    notice: '이벤트 ・ 공지사항',
  } as any;

  useEffect(() => {
    getDocsArrayWithWhere<InsightDoc>(
      'insight',
      [['category', '==', category]],
      { sortKey: '_timeCreate', orderBy: 'desc' }
    ).then((docs) => {
      setInsights(docs);
    });
  }, [category]);

  return (
    <div className={`${classes.container} maxWidth`}>
      <div className={classes.containerHeader}>
        <h2>
          <FontAwesomeIcon
            icon={faAngleLeft}
            color="#ea0029"
            onClick={() => navigate('/insight')}
          />{' '}
          {categoryMap[category ?? 'category']}
        </h2>
      </div>
      <div className={classes.grid}>
        {insights.map((insight, i) => {
          return <InsightItem key={i} insight={insight} />;
        })}
      </div>
    </div>
  );
};

export default InsightAll;
