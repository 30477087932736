/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';

import Button from '../../components/UI/Button';
import { createDoc } from '../../shared/firebaseManager';
import { StoreInLead } from '../../shared/interface';
import { normalizingTel } from '../../shared/util';
import classes from './ContactForm.module.css';

const ContactForm = () => {
  const [formValue, setFormValue] = useState<StoreInLead>({
    title: '입점문의',
    name: '',
    phone: '',
    email: '',
    brand: '',
    storein: '',
    path: '',
    categories: '',
    sites: '',
  });
  const [privacy, setPrivacy] = useState(false);
  const [formError, setFormError] = useState({phone: false, email: false});
  const navigate = useNavigate();

  const leadInLog = () => {
    // GA
    ReactGA.event({ category: '상담문의', action: '완료', value: 1 });
    /** 파트너팀 요청에 의해 추가된 코드로 '미래아엔씨' 마케팅 회사에서 전환율 분석을 위해 사용합니다. */
    (window as any).mi_chat_conv.send({ mi_type: 'CV_1', mi_val: 'Y' });
  }

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const { name, phone, email, storein, path, brand, categories, sites } = formValue;

    if (!name || !phone || !email || !storein || !path || !categories || !sites) {
      alert('필수 항목을 모두 입력해주세요.');
      return;
    }

    if (!privacy) {
      alert('개인정보 수집 및 활용에 동의해주세요.');
      return;
    }

    try {
      localStorage.setItem('contact', JSON.stringify({name, phone, email, brand, storein, path, categories, sites}));
      await createDoc('storeInLead', undefined, {
        ...formValue,
        phone: phone.replaceAll('-', '')
      });
      leadInLog();

      navigate('/contact/result');
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    if (name === 'privacy') {
      setPrivacy(value as boolean);
    } else {
      validate(name, value as string);
      if(name === 'phone') {
        setFormValue({ ...formValue, phone: normalizingTel(value as string) });
      } else {
        setFormValue({ ...formValue, [name]: value });
      }
    }
  };

  const handleCheckboxChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const checked: string[] = [];
    document.getElementsByName(target.name).forEach((element: any) => {
      if (element.checked) {
        checked.push(element.id);
      }
    });

    setFormValue({ ...formValue, [target.name]: checked.join(', ') });
  };

  function validate(type: string, value: string) {
    switch (type) {
      case 'phone':
        const validateTel = /(011|010|070|02|031|032|033|041|042|043|044|051|052|053|054|055|061|062|063|064)-(\d{3,4})-(\d{4})/.test(value);
        setFormError({...formError, phone: !validateTel});
        break;
      case 'email':
        const validateEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/.test(value);
        setFormError({...formError, email: !validateEmail});
        break;
      default:
        return true;
    }
  }

  return (
    <form className={classes.form} onSubmit={onSubmit}>
      <div className={classes.formInputContainer}>
        {[
          { key: 'name', name: '이름', value: formValue.name, required: true },
          {
            key: 'phone',
            name: '연락처',
            value: formValue.phone,
            required: true,
            type: 'tel',
            error: formError.phone
          },
          {
            key: 'email',
            name: '이메일 주소',
            value: formValue.email,
            required: true,
            type: 'email',
            error: formError.email
          },
          {
            key: 'brand',
            name: '운영중인 업소명・브랜드',
            value: formValue.brand,
            required: false,
          },
        ].map((item, i) => {
          return (
            <div key={i} className={classes.formInput}>
              <label
                className={item.required ? classes.required : ''}
                htmlFor={item.key}
              >
                {item.name}
              </label>
              <input
                type={item.type ?? 'text'}
                name={item.key}
                value={item.value}
                onChange={handleChange}
              />
              { item?.error &&
                <span className={classes.error}>{item.key === 'phone' ? '연락처' : '이메일' } 형식이 맞지 않습니다.</span>
              }
            </div>
          );
        })}
        {[
          {
            key: 'storein',
            name: '입점 희망 시점',
            options: ['1개월이내', '2~3개월', '4~6개월', '그외'],
            value: formValue.storein,
            required: true,
          },
          {
            key: 'path',
            name: '고스트키친을 알게 된 경로',
            options: [
              '네이버검색',
              '네이버카페',
              '페이스북',
              '인스타그램',
              '유튜브',
              '지인소개',
              '기타',
            ],
            value: formValue.path,
            required: true,
          },
        ].map((item, i) => {
          return (
            <div key={i} className={classes.formInput}>
              <label
                className={item.required ? classes.required : ''}
                htmlFor={item.key}
              >
                {item.name}
              </label>
              <select
                name={item.key}
                value={item.value}
                onChange={handleChange}
              >
                <option value="">선택하세요</option>
                {item.options.map((option, j) => {
                  return (
                    <option key={j} value={option}>
                      {option}
                    </option>
                  );
                })}
              </select>
            </div>
          );
        })}
      </div>
      <div className={classes.formCheckbox}>
        <p className={classes.required}>희망 음식 분야 (중복선택 가능)</p>
        <div>
          {[
            '한식',
            '양식',
            '중식',
            '일식',
            '분식',
            '치킨',
            '피자',
            '족발/보쌈',
            '찜/탕',
            '도시락',
            '카페/디저트',
          ].map((category, i) => {
            return (
              <div key={i}>
                <input
                  type="checkbox"
                  name="categories"
                  id={category}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor={category}>{category}</label>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.formCheckbox}>
        <p className={classes.required}>입점 희망 지점 (중복선택 가능)</p>
        <div>
          {[
            '강남역점',
            '삼성점',
            '송파점',
            '노원점',
            '관악점(봉천)',
            '관악2호점(낙성대)',
            '영등포점',
          ].map((site, i) => {
            return (
              <div key={i}>
                <input
                  type="checkbox"
                  name="sites"
                  id={site}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor={site}>{site}</label>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <p>개인정보 수집 및 활용 동의서</p>
        <input
          type="checkbox"
          name="privacy"
          id="privacy"
          onChange={handleChange}
        />
        <label htmlFor="privacy">(필수) 개인정보 수집 및 활용 동의</label>
        <div className={classes.privacy}>
          (주)단추로끓인수프는 고스트키친 홈페이지 서비스 고객상담 및 고지사항
          전달 등을 위해 아래와 같이 개인정보를 수집, 이용하며 수집한 정보는
          약관 동의로부터 1년 후 파기합니다. 1. 실명 : 고객상담 2. 전화번호 :
          고객 상담 및 CS 3. 이메일 : 서비스 내용소개 및 변경사항 및 고지사항
          전달 귀하는 (주)단추로끓인수프의 서비스 이용에 필요한 최소한의
          개인정보 수집 및 이용에 동의하지 않을 수 있으나, 동의를 거부할 경우
          입점상담이 불가합니다.
        </div>
      </div>
      <div className={classes.buttons}>
        <Button
          disabled={
            !formValue.name ||
            !formValue.phone ||
            !formValue.email ||
            !formValue.storein ||
            !formValue.path ||
            !formValue.categories ||
            !formValue.sites ||
            !privacy
          }
        >
          신청하기
        </Button>
      </div>
    </form>
  );
};

export default ContactForm;
