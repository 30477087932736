/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { useNavigate } from 'react-router-dom';

import Button from '../../components/UI/Button';
import classes from './Site.module.css';

/** KT클라우드에 올라가 있는 site이미지 입니다. 손가락에서도 해당 이미지를 지점별 대표로 사용하고 있습니다. */
const ktStaticPrefix = 'https://ssproxy.ucloudbiz.olleh.com/v1/AUTH_d722d13e-44ea-44ad-8c9b-2f5763ce3d40/ghostkitchen/site/';

const Site = () => {
  const navigate = useNavigate();
  const EventItems = sites.filter((site) => site.state === 'event').map((site, siteIndex) => {
    return (
      <div className={classes.eventSiteItem} key={siteIndex}>
        <div className={classes.imgSquareBox} style={{backgroundImage: `url('${ktStaticPrefix}gk-${site.siteKey}-front.jpg')`}}/>
        <h3>{site.siteName}</h3>
        {site.eventDescription?.map((desc, descIndex) => <p key={descIndex}>{desc}</p>)}
        <Button onClick={() => navigate(site.siteKey)}>자세히 보기</Button>
      </div>
    );
  });

  const SiteItems = sites.filter((site) => site.state !== 'event').map((site, siteIndex) => {
    return (
      <div className={`${classes.contentBox} ${classes.siteItem}`} key={siteIndex}>
        <h3>{site.siteName}</h3>
        <div className={classes.siteItemImg}>
          <div className={classes.imgRectBox} style={{backgroundImage: `url('${ktStaticPrefix}gk-${site.siteKey}-front.jpg')`}}/>
          <div className={classes.buttons}>
            <Button onClick={() => navigate(site.siteKey)}>자세히 보기</Button>
            <Button borderButton={true}>{site.state === 'waiting' ? '입점대기 가능' : '입점 가능'}</Button>
          </div>
        </div>

        <div className={classes.siteItemContent}>
          <p>총 키친 수 {site.rooms}개</p>
          <p>{site.address}</p>
          <ul>
            {site.description.map((desc, descIndex) => <li key={descIndex}>{desc}</li>)}
          </ul>
        </div>
      </div>
    );
  });
  
  return (
    <div className={classes.site}>
      <header>
        <p>주방시설과 배달상권을 확인하세요</p>
        <h1>고스트키친 지점소개</h1>
      </header>

      <section className={classes.pinkBg}>
        <div className='maxWidth'>
          <h2>이벤트 지점</h2>
          <div className={classes.contentBox}>
            {EventItems}
          </div>
        </div>
      </section>

      <section>
        <div className='maxWidth'>
          <h2>지점별 소개</h2>
          <div className={classes.siteList}>
            {SiteItems}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Site;

interface SiteItem {
  siteKey: string;
  siteName: string;
  rooms: number;
  address: string;
  description: string[];
  state: 'waiting' | 'available' | 'event';
  eventDescription?: string[];
}

const sites: SiteItem[] = [
  {
    siteKey: 'ydp',
    siteName: '영등포점',
    rooms: 23,
    address: '서울특별시 영등포구 신길로 39, 본관 3층',
    description: [
      '7호선 신풍역 도보 10분',
      '영등포구 주요 상권',
      '반경 3km 이내 총 인구수 54만명 이상',
      '반경 3km 이내 20~40대 인구수 24만명 이상'
    ],
    state: 'event',
    eventDescription: [
      '#90일 렌트프리',
      '#오픈이벤트'
    ]
  },
  {
    siteKey: 'gwanak',
    siteName: '관악1호점',
    rooms: 20,
    address: '서울 관악구 장군봉1길 56',
    description: [
      '서울시 유동인구 1위 상권',
      '삼성전자 서초 사옥 1분 거리',
      '2호선 강남역 도보 5분 거리'
    ],
    state: 'event',
    eventDescription: [
      '#30일 렌트프리',
      '#임대료 할인 이벤트'
    ]
  },
  {
    siteKey: 'kangnam',
    siteName: '강남역점',
    rooms: 27,
    address: '서초구 서운로 138, 동아티워 B1',
    description: [
      '서울시 유동인구 1위 상권',
      '삼성전자 서초 사옥 1분 거리',
      '2호선 강남역 도보 5분 거리'
    ],
    state: 'waiting'
  },
  {
    siteKey: 'samsung',
    siteName: '삼성점',
    rooms: 15,
    address: '강남구 삼성로 571, 케이빌딩 B1',
    description: [
      '서울시 직장 인구 1,2위 상권',
      '세대별 1인 가구 중 2040세대 약 70% 차지',
      '삼성동 내 아파트 단지 약 4천여 세대 거주'
    ],
    state: 'available'
  },
  {
    siteKey: 'songpa',
    siteName: '송파점',
    rooms: 25,
    address: '송파구 석촌호수로 210, 해링턴타워 B1',
    description: [
      '2040세대 1인 가구 거주지역 3위',
      '헬리오시티 배후 1만여 세대 거주',
      '가락시장 차로 9분 거리'
    ],
    state: 'available'
  },
  {
    siteKey: 'nowon',
    siteName: '노원점',
    rooms: 11,
    address: '노원구 노해로 455, 인산빌딩 B1',
    description: [
      '강북 소득 1위 상권',
      '35개 행정동 배달 지역 (대규모 아파트 단지, 대학가 중심)',
      '서울시 단위면적 당 인구수 가장 많음'
    ],
    state: 'available'
  },
  {
    siteKey: 'gwanak',
    siteName: '관악1호점',
    rooms: 20,
    address: '관악구 장군봉1길 56, 우형빌딩 B1',
    description: [
      '국내 1인 가구 최대 상권이자 2030세대 76% 차지',
      '신림동 대학가, 연구단지 등 단체주문 발생지역',
      '봉천역 도보 2분거리, 봉천종합시장 입구에 지점 위치',
    ],
    state: 'available'
  },
  {
    siteKey: 'gwanak2',
    siteName: '관악2호점',
    rooms: 17,
    address: '관악구 남부순환로 1904, 에덴빌딩 B1',
    description: [
      '32개 행정동 배달지역 (동작구, 관악구, 서초구 일부)',
      '국내 1인 가구 최대 상권',
      '1인 가구 중 2030세대 약 76% 차지',
      '신림동 대학가, 연구단지 단체주문 발생 지역'
    ],
    state: 'available'
  },
  {
    siteKey: 'ydp',
    siteName: '영등포점',
    rooms: 23,
    address: '영등포구 신길로39, 썬프라자 3층',
    description: [
      '61개 행정동을 커버하는 배달권역',
      '500m 이내 영등포 아파트 단지 7,000세대 거주',
      '구로구 내 오피스, 병원 밀집 지역',
      '관악구 국내 1인 가구 최다 거주'
    ],
    state: 'available'
  },
]
