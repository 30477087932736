/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { useEffect, useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InsightDetail from '../InsightDetail/InsightDetail';
import InsightAll from '../InsightAll/InsightAll';
import InsightItem from '../../components/InsightItem/InsightItem';
import { getDocsArrayWithWhere } from '../../shared/firebaseManager';
import { InsightDoc } from '../../shared/interface';

import classes from './Insight.module.css';
import NotFound from '../NotFound/NotFound';

const Insight = () => {
  return (
    <div className={classes.insight}>
      <div className={classes.headerContainer}>
        <h1>
          고스트키친이 드리는
          <br />
          지금 꼭 필요한 창업백서
        </h1>
      </div>
      <Routes>
        <Route path="/" element={<InsightHome />} />
        <Route path="/detail/:category/:id" element={<InsightDetail />} />
        <Route path="/all/:category" element={<InsightAll />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

const InsightHome = () => {
  const [insights, setInstights] = useState<InsightDoc[]>([]);

  useEffect(() => {
    getDocsArrayWithWhere<InsightDoc>('insight', [], {
      sortKey: '_timeCreate',
      orderBy: 'desc',
    }).then((docs) => {
      setInstights(docs);
    });
  }, []);

  return (
    <>
      <div className={`${classes.card} maxWidth`}>
        <div className={classes.cardHeader}>
          <h2>고키 사장님 스토리</h2>
          <p>
            <Link to="./all/ceostory">
              전체보기 <FontAwesomeIcon icon={faAngleRight} />
            </Link>
          </p>
        </div>
        <div className={classes.cardContent}>
          {insights
            .filter((doc) => doc.category === 'ceostory')
            .slice(0, 3)
            .map((item, i) => {
              return <InsightItem key={i} insight={item} />;
            })}
        </div>
      </div>
      <div className={`${classes.card} maxWidth`}>
        <div className={classes.cardHeader}>
          <h2>외식업 정보 공유</h2>
          <p>
            <Link to="./all/info">
              전체보기 <FontAwesomeIcon icon={faAngleRight} />
            </Link>
          </p>
        </div>
        <div className={classes.cardContent}>
          {insights
            .filter((doc) => doc.category === 'info')
            .slice(0, 3)
            .map((item, i) => {
              return <InsightItem key={i} insight={item} />;
            })}
        </div>
      </div>
      <div className={`${classes.card} maxWidth`}>
        <div className={classes.cardHeader}>
          <h2>고키 통계분석</h2>
          <p>
            <Link to="./all/analytics">
              전체보기 <FontAwesomeIcon icon={faAngleRight} />
            </Link>
          </p>
        </div>
        <div className={classes.cardContent}>
          {insights
            .filter((doc) => doc.category === 'analytics')
            .slice(0, 3)
            .map((item, i) => {
              return <InsightItem key={i} insight={item} />;
            })}
        </div>
      </div>
      <div className={`${classes.card} maxWidth`}>
        <div className={classes.cardHeader}>
          <h2>이벤트 ・ 공지사항</h2>
          <p>
            <Link to="./all/notice">
              전체보기 <FontAwesomeIcon icon={faAngleRight} />
            </Link>
          </p>
        </div>
        <div className={classes.cardContent}>
          {insights
            .filter((doc) => doc.category === 'notice')
            .slice(0, 3)
            .map((item, i) => {
              return <InsightItem key={i} insight={item} />;
            })}
        </div>
      </div>
    </>
  );
};

export default Insight;
