/*
 * © 2022 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './Popup.module.css';

const Popup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  const link = (event: any) => {
    navigate('/insight/detail/notice/6SPrGOPqbRSkgQRPo4zO');
    closePopup(event);
  };

  const closePopup = (event: any) => {
    event.stopPropagation();
    setIsVisible(false);
  };

  return (
    <div
      className={`${classes.backdrop} ${
        isVisible ? classes.visible : classes.invisible
      }`}
    >
      <div className={classes.popupContainer} onClick={link}>
        <div className={classes.closeContainer} onClick={closePopup}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </div>
        <img
          src="https://ssproxy.ucloudbiz.olleh.com/v1/AUTH_d722d13e-44ea-44ad-8c9b-2f5763ce3d40/ghostkitchen/homepage/insight/thumbnail/220308_thumbnail.jpg"
          alt="팝업이미지"
        />
      </div>
    </div>
  );
};

export default Popup;
