import { format, parse } from 'date-fns';
import { ko } from 'date-fns/locale';
import { Diff } from 'deep-diff';

export async function sleep(ms: number) {
  await new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * 입력 과정 중에 자동으로 -를 붙여준다.
 * util.ts에 있는 noramlizeTel과는 쓰임이 다르다.
 */
export function normalizingTel(telNo: string) {
  // 숫자 이외에는 모두 제외한다.
  telNo = telNo.replace(/[^0-9]/g, '');

  if (telNo[0] !== '0') {
    return '';
  }
  // 2번째 숫자가 허용되지 않는 숫자라면 거부
  if (!['1', '2', '3', '4', '5', '6', '7'].includes(telNo[1])) {
    return telNo[0];
  }

  if (telNo.match(/^010|050|070|011/)) {
    // 국번이 0이나 1로 시작하지 않는다.
    if (['0', '1'].includes(telNo[3])) {
      return telNo.substr(0, 3);
    }

    if (telNo.length === 12) {
      return `${telNo.substr(0, 4)}-${telNo.substr(4, 4)}-${telNo.substr(8, 4)}`;
    } else if (telNo.length === 10) {
      return `${telNo.substr(0, 3)}-${telNo.substr(3, 3)}-${telNo.substr(6, 4)}`;
    } else if (telNo.length > 7) {
      return `${telNo.substr(0, 3)}-${telNo.substr(3, 4)}-${telNo.substr(7, 4)}`;
    } else if (telNo.length > 3) {
      return `${telNo.substr(0, 3)}-${telNo.substr(3, 4)}`;
    } else {
      return telNo;
    }
  } else {
    if (telNo[1] === '2') {
      // 02
      if (telNo.length > 9) {
        return `${telNo.substr(0, 2)}-${telNo.substr(2, 4)}-${telNo.substr(6, 4)}`;
      } else if (telNo.length > 5) {
        return `${telNo.substr(0, 2)}-${telNo.substr(2, 3)}-${telNo.substr(5, 4)}`;
      } else if (telNo.length > 2) {
        return `${telNo.substr(0, 2)}-${telNo.substr(2, 3)}`;
      } else {
        return telNo;
      }
    } else {
      // 031-3 / 041-4 / 051-5 / 061-4
      if (telNo.length > 9) {
        return `${telNo.substr(0, 3)}-${telNo.substr(3, 3)}-${telNo.substr(6, 4)}`;
      } else if (telNo.length > 6) {
        return `${telNo.substr(0, 3)}-${telNo.substr(3, 3)}-${telNo.substr(6, 4)}`;
      } else if (telNo.length > 3) {
        return `${telNo.substr(0, 3)}-${telNo.substr(3, 3)}`;
      } else {
        return telNo;
      }
    }
  }
}

/**
 * - 를 삽입한다.
 */
export function normalizeTel(telNo: string) {
  if (telNo == null || telNo === '') {
    return '';
  }
  // 숫자 이외에는 모두 제외한다.
  telNo = telNo.replace(/[^0-9]/g, '');

  // 2018-11-15 부터는 050으로 변환해서 FS에 저장하기 때문에 불펼요할 수 있다.
  telNo = telNo.replace(/^090/, '050');

  // 010- , 070-
  let matches = telNo.match(/^(0[17][01])(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  // 050은 4자리 식별번호를 사용하지만 3자리가 익숙하니 12자리가 아닌 경우에는 050에서 끊어준다.
  // 050-AAA?-BBBB
  matches = telNo.match(/^(050)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  // 050X-AAAA-BBBB
  matches = telNo.match(/^(050.)(.{4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  matches = telNo.match(/^(02)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  matches = telNo.match(/^(0..)(.{3,4})(.{4})$/);
  if (matches) {
    return `${matches[1]}-${matches[2]}-${matches[3]}`;
  }

  return telNo;
}

/**
 * 사업자 번호 형식을 만든다.
 */
export function normalizingBusinessNumber(businessNumber: string) {
  // 1. 숫자 이외에는 모두 제외한다.
  // 2. 3-2-5+로 분리한다.
  // 10자리가 되지 않도라도 match 된다.
  // 10자리가 넘는 숫자는 자르지 않고 뒤에 붙인다.
  const groups = businessNumber.replace(/[^0-9]/g, '').match(/^(\d{0,3})(\d{0,2})(\d*)$/) ?? [];
  return groups.filter(group => group !== '').join('-');
}

/**
 * '고스트키친 삼성점 04호'를 '삼성점 04호'로 변환한다.
 */
export function trimOrganization(text: string) {
  return text?.replace(/^고스트키친 /, '');
}

/**
 * '고스트키친 삼성점 04호'를 '04호'로 변환한다.
 */
export function trimSite(text: string) {
  return text?.replace(/^고스트키친 [^\s]+\s/, '');
}

/**
 *
 * @param dateStr  '2018-01-01T12:34:56+0900'
 */
export function weekdayKR(dateStr: string): string {
  // Safari는 +09:00은 지원해도 +0900은 지원하지 않는다.
  return format(parse(dateStr, `yyyy-MM-dd'T'HH:mm:ssXX`, new Date()), 'eee', { locale: ko });
}

/**
 * 여러 형태의 시간을 ISO 형식의 문자열로 변환한다.
 * date2iso.test.ts에 사용예 확인
 */
export function toDate(date: string | number | Date): Date {
  if (date === undefined) {
    throw TypeError(`undfiend date format @ toDate()`);
  }

  if (date instanceof Date) {
    return date;
  } else if (typeof date === 'number') {
    if (date > 9999999999) {
      // 밀리초라면
      return new Date(date);
    } else {
      // 초라면
      return new Date(date * 1000);
    }
  } else {
    // Case 0. '2019-05-03T12:08:38+0900'
    let match = date.match(/^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})([+-]\d{2}):?(\d{2})$/);
    if (match) {
      return parse(date, `yyyy-MM-dd'T'HH:mm:ssXX`, new Date());
    }

    // Case 1-1. '2019-05-03 12:08:38'
    // Case 1-2. '2019-05-03 12:08:38.0'
    match = date.match(/^(\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2})(\.0)?$/);

    if (match) {
      return parse(`${match[1]}T${match[2]}+0900`, `yyyy-MM-dd'T'HH:mm:ssXX`, new Date());
    }

    // Case 2.
    match = date.match(/^(\d{4}\d{2}\d{2})T(\d{2}\d{2}\d{2})Z/);

    if (match) {
      return parse(`${match[1]}T${match[2]}+0000`, `yyyyMMdd'T'HHmmssXX`, new Date());
    }

    // Case 3. 1559097490
    // 단위가 초라면 10자가 될 것이다.
    match = date.match(/^\d{10}$/);
    if (match) {
      return new Date(parseInt(date, 10) * 1000);
    }

    // 단위가 밀리초라면 13자가 될 것이다.
    match = date.match(/^\d{13}$/);
    if (match) {
      return new Date(parseInt(date, 10));
    }
  }

  throw TypeError(`Unexpected date format : ${date}`);
}

/**
 * 두 시각 차이를 계산해서 M:ss 형식으로 변환한다.
 * timestamp2 - timestamp1
 *
 * round는 1,2초의 차이가 나는 경우에 보정하기 위해서 사용한다.
 */
export function diffTime(timestamp1: string | number | Date, timestamp2: string | number | Date, round?: boolean) {
  if (round == null) {
    round = false;
  }

  const ret = {
    m: 0,
    s: 0,
    sStr: '00'
  };

  if (timestamp1 == null || timestamp2 == null) {
    return ret;
  }

  // Safari는 +09:00은 지원해도 +0900은 지원하지 않는다.
  const date1 = toDate(timestamp1).getTime();
  const date2 = toDate(timestamp2).getTime();

  const diffSec = Math.floor((date2 - date1) / 1000);

  let m = Math.floor(diffSec / 60);
  let s = diffSec % 60;

  // 50초보다 큰 경우에는 1분으로 계산한다.
  // 초는 사용하지 않고 분만 표시하는 경우에 사용
  if (round && s > 50) {
    s = 0;
    m += 1;
  }

  const sStr = s < 10 ? `0${s}` : `${s}`;

  ret.m = m;
  ret.s = s;
  ret.sStr = sStr;

  return ret;
}

/**
 * 초를 분과 초로 분리해 준다.
 * 포맷팅을 위한 변환이기 때문에 음수의 경우에 m * 60 + s가 $seconds가 되지는 않는다.
 *
 * 69 => { 1, 9, '09'}
 * -69 => { -1, 9, '09'}
 *
 */
export function formatSeconds($seconds: number | string, round = false) {
  let seconds = (typeof $seconds === 'string') ? parseInt($seconds, 10) : $seconds;

  // 음수라면 양수로 만든다.
  const bReverse = seconds < 0 ? true : false;
  seconds = bReverse ? -seconds : seconds;

  let m = Math.floor(seconds / 60);
  let s = seconds % 60;

  const ret = {
    m: 0,
    s: 0,
    sStr: '00'
  };

  // 50초보다 큰 경우에는 1분으로 계산한다.
  // 초는 사용하지 않고 분만 표시하는 경우에 사용
  if (round && s > 50) {
    s = 0;
    m += 1;
  }

  const sStr = s < 10 ? `0${s}` : `${s}`;

  ret.m = bReverse ? -m : m;
  ret.s = s;
  ret.sStr = sStr;

  return ret;
}

/**
 * 1 -> 'A', 2 -> 'B', ....
 */
export function numberToAlphabet(num: number) {
  return String.fromCharCode(num + 64);
}

/**
 * deep-diff의 결과를 보기 좋게 보여준다.
 */
export function formatDiffs(diffs: ReadonlyArray<Diff<any>>) {
  let output = '';

  function formatDiff(diff: Diff<any>) {
    switch (diff.kind) {
      case 'N':
        return `[${diff.kind}] ${diff.path ? diff.path.join('.') : 'N/A'} : ${JSON.stringify(diff.rhs, null, 2)}`;
      case 'E':
        // tslint:disable-next-line: max-line-length
        return `[${diff.kind}] ${diff.path ? diff.path.join('.') : 'N/A'} : ${JSON.stringify(diff.lhs, null, 2)} => ${JSON.stringify(diff.rhs, null, 2)}`;
      case 'D':
        return `[${diff.kind}] ${diff.path ? diff.path.join('.') : 'N/A'} : ${JSON.stringify(diff.lhs, null, 2)}`;
      default:
        return `${JSON.stringify(diff, null, 2)}`;
    }
  }

  for (const diff of diffs) {
    switch (diff.kind) {
      case 'A':
        output += `[${diff.kind}] ${diff.path ? diff.path.join('.') : 'N/A'} : ( ${formatDiff(diff.item)} )\n`;
        break;

      // 'N' | 'E' | 'D'
      default:
        output += `${formatDiff(diff)}\n`;
        break;
    }
  }

  return output;
}

/**
 * 아래와 같은 문자열에서 태그를 제거하기 위해 사용한다.
 * <font color='#000000'>10,000원 ~ </font>
 * 일단 간단하게 <.*?>를 태그의 시작과 끝으로 가정한다. *?는 non-greedy
 *
 * @bSplit true이면 배열을 리턴한다. 그렇지 않은 경우에는 문자열을 리턴한다.
 */
export function removeTags(str: string, bSplit = false) {
  if (bSplit) {
    return str.split(/<.*?>/).filter(s => s.trim().length > 0);
  } else {
    return str.split(/<.*?>/).filter(s => s.trim().length > 0).join('').trim();
  }
}

/**
 *
 * WGS84 좌표계의 두 점 사이의 거리를 구한다.
 * refer: https://blog.asamaru.net/2015/09/14/calculate-distance-between-two-wgs84-points/
 *
 * @param lat1 좌표1의 위도
 * @param lng1 좌표1의 경도
 * @param lat2 좌표2의 위도
 * @param lng2 좌표2의 경도
 * @returns 단위는 미터
 */
export function calcGeoDistance(lat1: number, lng1: number, lat2: number, lng2: number) {
  function deg2rad(deg: number) {
    return deg * (Math.PI / 180);
  }

  // const R = 6371000; // Radius from wikipedia (https://en.wikipedia.org/wiki/Earth_radius)
  const R = 6372000;  // 부릉의 값과 비교해서 약간 조정한 값
  const dLat = deg2rad(lat2 - lat1);  // deg2rad below
  const dLon = deg2rad(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
    Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in meter

  // meter
  return Math.floor(d);
}

/**
 * 현재 시간 기준으로 지정한 시간만큼의 이전이나 이후의 시간 분을 계산한다.
 *
 * @param hourOffsetFromNow 현재 시간 기준으로 더하는 시간값
 */
export function shiftedHoursMinutes(hourOffsetFromNow: number) {
  if (hourOffsetFromNow < -23) {
    console.error(`${hourOffsetFromNow}는 -23보다 작기 때문에 atDate와 함께 사용하는 경우에 원하는 대로 동작하지 않습니다.`);
  }

  const now = new Date();
  // hourOffsetFromNow 만큼 시간 변경
  now.setHours(now.getHours() + hourOffsetFromNow);
  const hours = now.getHours();
  const minutes = now.getMinutes();

  return [hours, minutes];
}

const diffTimestampInstances: {
  [instanceKey: string]: [number, number];
} = {};
/**
 * 실행하는 시점 간의 시간 차이를 구할 때 사용한다.
 */
export function diffTimestamp(instanceKey: string) {
  const nowMilli = Date.now();
  if (diffTimestampInstances[instanceKey] === undefined) {
    diffTimestampInstances[instanceKey] = [nowMilli, 0];
  }

  // 1. get old time
  const [oldTimestamp, oldCount] = diffTimestampInstances[instanceKey];
  // 2. calculate diffTIme
  const diffMilli = nowMilli - oldTimestamp;

  const sec = Math.floor(diffMilli / 1000);
  const milli = String(diffMilli % 1000);

  // 3. update oldTIme
  diffTimestampInstances[instanceKey] = [nowMilli, oldCount + 1];

  return `[${oldCount + 1}] ${sec}.${milli.padStart(3, '0')}`;
}
