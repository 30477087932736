/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { useNavigate } from 'react-router-dom';

import classes from '../SiteInfoSimple.module.css';
import Button from '../../../components/UI/Button';

import Interior1 from 'assets/img/site-info/gk_interior1.jpg';
import Interior2 from 'assets/img/site-info/gk_interior2.jpg';
import Interior3 from 'assets/img/site-info/gk_interior3.jpg';
import Service2 from 'assets/img/site-info/gk_service2.jpg';
import Service3 from 'assets/img/site-info/gk_service3.jpg';
import Service4 from 'assets/img/site-info/gk_service4.jpg';
import Service5 from 'assets/img/site-info/gk_service5.png';
import SongpaArea from 'assets/img/site-info/songpa_area.png';
import SongpaInterior from 'assets/img/site-info/songpa_interior.jpg';

/** KT클라우드에 올라가 있는 site이미지 입니다. 손가락에서도 해당 이미지를 지점별 대표로 사용하고 있습니다. */
const ktStaticPrefix = 'https://ssproxy.ucloudbiz.olleh.com/v1/AUTH_d722d13e-44ea-44ad-8c9b-2f5763ce3d40/ghostkitchen/site/';

const Songpa = () => {
  const navigate = useNavigate();

  return (
    <div className={classes.siteInfoSimple}>
      <header style={{backgroundImage: `url('${ktStaticPrefix}gk-songpa-front.jpg')`}}>
        <h1>고스트키친 송파점</h1>
      </header>

      <section className={`${classes.topContent} maxWidth`}>
        <h2>송파 핵심 상권 배달에 최적화된 위치</h2>
        <div className={classes.flexBox}>
          <div>
            <img src={SongpaArea} alt='송파점 배달지역'/>
          </div>
          <div className={classes.sixth} style={{backgroundImage: `url(${SongpaInterior})`}}>
            <img src={SongpaInterior} alt='송파점'/>
          </div>
        </div>
        <div>
          <p>1인 가구, 다가구를 아우르는 배달권역</p>
          <ul>
            <li>2040세대 1인 가구 거주 TOP3 지역 (관악,강남,송파)</li>
            <li>2040세대 1인 가구 64.35% 차지 (1인가구 총 73,694명_21.7월 기준)</li>
            <li>헬리오시티 배후 1만여 세대</li>
          </ul>

          <p>편리한 식자재 구입</p>
          <ul>
            <li>국내최대 식자재 도매시장 ‘가락시장’ 차로 9분 거리</li>
          </ul>
        </div>
      </section>

      <section className={classes.yellowBg}>
        <div className='maxWidth'>
          <h2>배달전문점에 최적화된 개별 주방</h2>
          <div className={classes.flexBox}>
            <div><img src={Interior1} alt='주방내부1'/></div>
            <div className={classes.seventh}>
              <p>동선의 최적화</p>
              <span>재료 보관부터 조리,포장, 전달까지<br/>효율적인 동선을 고려해 설계했습니다</span>
            </div>
          </div>
          <div className={classes.flexBox}>
            <div><img src={Interior2} alt='주방내부2'/></div>
            <div className={classes.seventh}>
              <p>철저한 위생관리</p>
              <span>주방 전체를 스테인리스 스틸로 마감<br/>
              매일매일 세척과 살균이 용이합니다</span>
            </div>
          </div>
          <div className={classes.flexBox}>
            <div><img src={Interior3} alt='주방외관'/></div>
            <div className={classes.seventh}>
              <p>분리된 개별주방</p>
              <span>바닥부터 천장까지 완전밀폐된 독립공간<br/>
              이웃한 주방의 냄새와 소음을 막아줍니다</span>
            </div>
          </div>
        </div>
      </section>

      <section className={classes.grayBg}>
        <div className='maxWidth'>
          <h2>고키만의 남다른 서비스 5가지</h2>
          <div className={classes.flexBox}>
            <div className={classes.movieContainer}>
              <div>
                <iframe src="https://www.youtube.com/embed/5fr6yheJ6nc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
              </div>
            </div>
            <div className={classes.half}>
              <p>쉽고 편리한 TMS, 발가락</p>
              <span>오직 고스트키친에서만 제공해 드리는 강력한 통합 관리 시스템으로 여러 배달 플랫폼에서 발생하는 주문을 하나의 화면에서 관리합니다. 주문받고 관리하는 시간과 수고를 두 번의 클릭으로 해결할 수 있습니다.</span>
            </div>
          </div>
          <div className={classes.flexBox}>
            <div><img src={Service2} alt='매니저'/></div>
            <div className={classes.half}>
              <p>체계적인 준비와 온보딩 매니저</p>
              <span>복잡하게 느껴질 수 있는 배달창업을 고스트키친 매니저와 함께 쉽고 빠르게 시작할 수 있습니다. 오픈 후, 데이터 기반 자료와 지식으로 필요한 정보를 제공드립니다.</span>
            </div>
          </div>
          <div className={classes.flexBox}>
            <div><img src={Service3} alt='고스트키친 다이렉트'/></div>
            <div className={classes.half}>
              <p>새로운 매출확대 채널, 고스트키친 다이렉트</p>
              <span>지점 내 모든 메뉴의 모아서 주문 포장이 가능한 플랫폼과 배달, 주문 수수료가 낮은 고키 다이렉트 서비스를 제공합니다.</span>
            </div>
          </div>
          <div className={classes.flexBox}>
            <div><img src={Service4} alt='고객센터'/></div>
            <div className={classes.half}>
              <p>고키 핫라인 고객센터 운영</p>
              <span>가게에서 발생하는 문제와 요청을 다이렉트로 해결해 드리는 고객센터와 카카오톡 채널을 운영합니다.</span>
            </div>
          </div>
          <div className={classes.flexBox}>
            <div><img src={Service5} alt='뉴스레터'/></div>
            <div className={classes.half}>
              <p>배달트렌드 뉴스레터 제공</p>
              <span>장사에 바쁘신 사장님을 위해 가게 운영에 도움이 될 수 있도록 배달트렌드・인사이트 최신 정보를 무료로 제공해 드립니다.</span>
            </div>
          </div>

          <div className={classes.buttonContainer}>
            <Button onClick={() => navigate('/contact')}>상담 신청 하기</Button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Songpa;
