/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import logoRound from 'assets/img/logo_round.png';
import blogLogo from 'assets/img/blog_img.png';
import facebookLogo from 'assets/img/facebook_img.png';
import instagramLogo from 'assets/img/insta_img.png';
import youtubeLogo from 'assets/img/youtube_img.png';
import classes from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <div className="maxWidth">
        <img src={logoRound} alt="Ghostkitchen" className={classes.logo} />
        <br />
        <a className={classes.tel} href="tel:15777005">1577-7005</a>
        <br />
        <a className={classes.tel2} href="tel:18333789">1833-3789 (영등포점 전용)</a>
        <div className={classes.biz}>
          주식회사 단추로끓인수프
          <br />
          대표자 최정이
          <br />
          사업자등록번호 672-81-00712
          <br />
          서울특별시 서초구 서초대로 398 BNK 디지털타워 510호
        </div>
        <div className={classes.contact}>
          입점문의 및 사업제휴 <a href="mailto:contact@ghostkitchen.net">contact@ghostkitchen.net</a>
          <br />
          미디어 관련 요청 <a href="mailto:press@buttonsoup.co">press@buttonsoup.co</a>
        </div>
        <div className={classes.sns}>
          <a target="_blank" rel="noreferrer" href="https://www.facebook.com/ghostkitchen.kr/">
            <img src={facebookLogo} alt="ghostkitchen 페이스북" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://instagram.com/ghostkitchen_official?igshid=aqmtp423ew1e">
            <img src={instagramLogo} alt="ghostkitchen 인스타그램" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCtUhxHw0Hgva-ANQ5-5UUjw">
            <img src={youtubeLogo} alt="ghostkitchen 유튜브" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://blog.naver.com/ghost-kitchen">
            <img src={blogLogo} alt="ghostkitchen 네이버 블로그" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
