/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import classes from './Header.module.css';
import logo from 'assets/img/logo.png';

const Header = (props: any) => {
  const [isScrolled, setIsScrolled]: any = useState(window.scrollY);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const subpageMapping: { [key: string]: string } = {
    kangnam: '강남역',
    samsung: '삼성',
    songpa: '송파',
    nowon: '노원',
    gwanak: '관악1호',
    gwanak2: '관악2호',
    ydp: '영등포',
    ceostory: '사장님스토리',
    info: '정보공유',
    analytics: '통계분석',
    notice: '공지',
  };

  const [path, ...detail] = pathname.replace('/', '').split('/');

  onscroll = (event: any) => {
    setIsScrolled(window.scrollY > 60);
  };

  return (
    <header
      className={`${classes.header} ${
        isScrolled || pathname === '/contact/result' ? classes.scrolled : ''
      }`}
    >
      <div className={`${classes.container} maxWidth`}>
        <div className={classes.logoContainer}>
          <img
            src={logo}
            alt="로고"
            onClick={() => {
              navigate('/');
            }}
          />
        </div>
        <nav className={classes.navigation}>
          <Link className={`${path === '' ? classes.selected : ''}`} to="/">
            홈
          </Link>
          <Link to="/site">
            <span className={`${path === 'site' ? classes.selected : ''}`}>
              지점소개
            </span>
            {path === 'site' && detail.length > 0 && (
              <p className={classes.navDetail}>{subpageMapping[detail[0]]}점</p>
            )}
          </Link>
          <Link to="/insight">
            <span className={`${path === 'insight' ? classes.selected : ''}`}>
              인사이트
            </span>
            {path === 'insight' && detail.length > 0 && (
              <p className={classes.navDetail}>{subpageMapping[detail[1]]}</p>
            )}
          </Link>
          <Link to="/contact">
            <div
              className={`${path === 'contact' ? classes.selected : ''} ${
                classes.contactItem
              }`}
            >
              상담문의
            </div>
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
