/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Link } from 'react-router-dom';
import classes from './InsightItem.module.css';

const InsightItem = (props: any) => {
  const { _id, category, thumbnail, title } = props.insight;
  return (
    <div className={classes.insightItem}>
      <Link to={`/insight/detail/${category}/${_id}`}>
        <div className={classes.thumbnail}>
          <img src={thumbnail} alt="섬네일" />
        </div>
        <p className={classes.title}>{title}</p>
        {/* <p className={classes.subtitle}>{subtitle}</p> */}
      </Link>
    </div>
  );
};

export default InsightItem;
