/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import classes from './Button.module.css';

interface IButton {
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  borderButton?: boolean;
}

const Button: React.FC<IButton> = (props) => {
  return (
    <button
      disabled={props.disabled ?? false}
      onClick={props.onClick}
      className={`${classes.button} ${
        props.borderButton ? classes.border : ''
      }`}
    >
      {props.children}
    </button>
  );
};

export default Button;
