/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

import './App.css';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Popup from './components/Popup/Popup';

import Home from './pages/Home/Home';
import Site from './pages/Site/Site';
import SiteInfo from './pages/SiteInfo/SiteInfo';
import NotFound from './pages/NotFound/NotFound';
import Insight from './pages/Insight/Insight';
import Contact from './pages/Contact/Contact';
import ContactResult from './pages/ContactResult/ContactResult';

ReactGA.initialize('UA-145097868-1');

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <WatchLocation />
        <Header />
        <Popup />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/site" element={<Site />} />
          <Route path="/site/*" element={<SiteInfo />} />
          <Route path="/insight/*" element={<Insight />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contact/result" element={<ContactResult />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

/**
 * pathname이 변경에 따라 필요한 기능을 수행한다.
 */
const WatchLocation = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    try {
      // 1. GA pageview 이벤트 호출
      ReactGA.pageview(pathname);
      // 2. 네이버 프리미엄 로그분석 pageview 이벤트 호출
      if (pathname !== '/contact/result') {
        (window as any).wcs?.pageview(pathname);
      }
    } catch (error) {
      console.error(error);
    }
    // 3. 스크롤을 최상단으로 이동
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default App;
