/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { useEffect } from 'react';
import { InsightDoc } from '../../shared/interface';
import classes from './ContactResult.module.css';

const ContactResult = () => {
  const keyMapping = {
    name: '이름',
    phone: '연락처',
    email: '이메일 주소',
    brand: '운영중인 업소명・브랜드',
    storein: '입점 희망 시점',
    path: '고스트키친을 알게 된 경로',
    categories: '희망 음식 분야',
    sites: '입점 희망 지점',
  };

  const formValue = localStorage.getItem('contact') ?? '{}';
  const parseValue: InsightDoc = JSON.parse(formValue);

  useEffect(() => {
    try {
      (window as any)._nasa = {};

      if ((window as any).wcs) {
        const cnv = (window as any).wcs.cnv('4', '1');
        (window as any)._nasa['cnv'] =  cnv ? cnv : 'cnvType=4|cnvValue=1';
        (window as any).wcs_do((window as any)._nasa);
      }
    } catch (error) {
      console.error(error);
    }
    return () => {
      localStorage.clear();
      (window as any)._nasa = {};
    };
  }, []);

  return (
    <div className={`${classes.result} maxWidth`}>
      <h2>상담 문의</h2>
      <table>
        <tbody>
          {Object.entries(parseValue).map(([_key, value]: [string, string]) => {
            const key = _key as
              | 'name'
              | 'phone'
              | 'email'
              | 'brand'
              | 'storein'
              | 'path'
              | 'categories'
              | 'sites';
            return (
              <tr key={key}>
                <td width={'40%'}>{keyMapping[key] ?? ''}</td>
                <td width={'50%'}>{value}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <p>상담 신청이 완료되었습니다.</p>
    </div>
  );
};

export default ContactResult;
