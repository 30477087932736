/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { useNavigate } from 'react-router-dom';

import classes from '../SiteInfo.module.css';
import Button from '../../../components/UI/Button';

import BestArea from 'assets/img/site-info/best_area.jpg';
import YdpArea from 'assets/img/site-info/ydp_area.png';
import YdpDetail from 'assets/img/site-info/ydp_detail.png';

/** KT클라우드에 올라가 있는 site이미지 입니다. 손가락에서도 해당 이미지를 지점별 대표로 사용하고 있습니다. */
const ktStaticPrefix = 'https://ssproxy.ucloudbiz.olleh.com/v1/AUTH_d722d13e-44ea-44ad-8c9b-2f5763ce3d40/ghostkitchen/site/';

const Ydp = () => {
  const navigate = useNavigate();

  return (
    <div className={classes.siteInfo}>
      <header style={{backgroundImage: `url('${ktStaticPrefix}gk-ydp-front.jpg')`}}>
        <h1>고스트키친 영등포점</h1>
      </header>

      <section className={`${classes.intro} maxWidth`} style={{backgroundImage: `url(${require(`assets/img/site-info/ydp_banner.jpg`)})`}}>
        <p className={classes.introTitle}>
          <b>61개 행정동의 배달권역</b>을 커버하는<br />
          <b>영등포 최고 배달 상권</b>에<br />
          <b className={classes.yellow}>고스트키친이 영등포점</b>을 오픈했습니다!
        </p>
        <p className={classes.introSubTitle}>
          <b>임대료</b> 85만원<br />
          <b>렌트프리</b> 3개월<br />
          <b>발가락 POS</b> 6개월 무료지원
        </p>
      </section>

      <section className='maxWidth'>
        <div className={classes.bestArea}>
          <div className={classes.contentTitle}>
            <strong>1. 영등포 최고 배달주문 상권</strong>
          </div>

          <div className={classes.bestAreaImgBox}>
            <img src={BestArea} alt='배달반경'/>
            <div className={classes.contentWithImg}>
              <div>
                <h3>배달 권역 내 잠재 고객 분포</h3>
                <h3>영등포점 반경 3.0km</h3>
              </div>
              <div className={classes.areaInfoList}>
                <div className={classes.areaInfoItem}>
                  <h4>총 인구</h4>
                  <p>54만명 이상</p>
                </div>
                <div className={classes.areaInfoItem}>
                  <h5>500m 이내<br />
                  아파트 밀집</h5>
                  <p>7,000 세대 이상 거주</p>
                </div>
                <div className={classes.areaInfoItem}>
                  <h5>배달앱 주 이용자<br />
                  2040 주거 인구</h5>
                  <p>24만명 이상</p>
                </div>
              </div>
          </div>
          </div>
        </div>
      </section>

      <section className='maxWidth'>
        <div className={classes.contentTitle}>
          <strong>2. 구로구, 관악구를 커버하는 배달권역</strong>
          <p><b>61개 행정동 커버하는 배달권역</b></p>
          <ul>
            <li>영등포구 | 500m 이내 7,000세대 이상 거주</li>
            <li>구로구 | 오피스, 병원 밀집 지역</li>
            <li>관악구 | 20~30대 1인 가구 최대 거주</li>
          </ul>
        </div>
        <img src={YdpArea} alt='영등포 배달지역'/>
      </section>

      <section className={classes.pinkBg}>
        <div className={`${classes.contentTitle} maxWidth`}>
          <strong>3. 최소 비용으로 매출 수수료 없이</strong>
        </div>
        <div className='maxWidth'>
          <div className={classes.priceList}>
            <strong>초기 지출비 1,095만 원</strong>
            <p className={classes.whiteBg}>
              <span>초기 입점 비용</span>
              <span>0원</span>
            </p>
            <p>
              <span>가스설치비</span>
              <span>95만 원</span>
            </p>
            <p className={classes.whiteBg}>
              <span>보증금</span>
              <span>1000만 원</span>
            </p>
          </div>

          <div className={classes.priceList}>
            <strong>매출 수수료 없이 매월 고정금액만!</strong>
            <p>
              <span>임대료</span>
              <span>85만 원</span>
            </p>
            <p className={classes.whiteBg}>
              <span>관리비</span>
              <span>20만 원</span>
            </p>
            <p>
              <span>매출 수수료</span>
              <span>0%</span>
            </p>
          </div>
        </div>
      </section>

      <section className={classes.grayBg}>
        <div className={`${classes.contentTitle} maxWidth`}>
          <strong>4. 위생적이고 편의성 높은 시설</strong>
          <p>창업자의 편의를 위한 공용시설, 기초시설 구비</p>
        </div>
        <div className={`${classes.imgList} maxWidth`}>
          {
            Array.from(Array(6).keys()).map(idx => (
              <img src={require(`assets/img/site-info/ydp_interior${idx + 1}.jpg`)} key={idx} alt='영등포점 내부 인테리어'/>
            ))
          }
        </div>
        <div className='maxWidth'>
          <img className={classes.imgBox} src={YdpDetail} alt='영등포 상세 정보'/>
        </div>

        <div className={classes.buttonContainer}>
          <Button onClick={() => navigate('/contact')}>상담 신청 하기</Button>
        </div>
      </section>
    </div>
  );
};

export default Ydp;
